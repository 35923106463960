var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length != 0
    ? _c(
        "section",
        [
          _c("div", { staticClass: "conversation-subject" }, [
            _vm._v(_vm._s(_vm.subject)),
          ]),
          _c(
            "b-form",
            { staticClass: "mt-2", attrs: { inline: "" } },
            [
              _vm.showCloseBtn
                ? _c(
                    "b-button",
                    {
                      staticClass: "no-border",
                      on: { click: _vm.closeConversation },
                    },
                    [
                      _c("i", { staticClass: "fal fa-times" }),
                      _c("span", { staticClass: "hidden-mobile" }, [
                        _vm._v(" " + _vm._s(_vm.$t("CLOSE"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.showCloseBtn
                ? _c(
                    "span",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "no-border",
                          attrs: { href: _vm.direktLink, target: "_blank" },
                        },
                        [
                          _c("i", { staticClass: "fal fa-external-link" }),
                          _c("span", { staticClass: "hidden-mobile" }, [
                            _vm._v(" " + _vm._s(_vm.$t("OPEN_NEW_TAB"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-button",
                { staticClass: "no-border", on: { click: _vm.refreshList } },
                [_c("i", { staticClass: "fal fa-sync" })]
              ),
              _c(
                "b-button",
                { staticClass: "no-border", on: { click: _vm.printBtn } },
                [
                  _c("i", { staticClass: "fal fa-print" }),
                  _c("span", { staticClass: "hidden-mobile" }, [
                    _vm._v(" " + _vm._s(_vm.$t("PRINT"))),
                  ]),
                ]
              ),
              _c("span", { staticClass: "i-100" }, [_vm._v(" ")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.count) +
                    " " +
                    _vm._s(_vm.$t("CONVERSATION_MESSAGES"))
                ),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
          _c("div", { attrs: { id: "conversationPrintMe" } }, [
            _c(
              "div",
              {
                key: _vm.conversationKey,
                staticClass: "m-2",
                staticStyle: { position: "relative" },
              },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "b-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        !_vm.isMe(item)
                          ? _c(
                              "b-col",
                              { attrs: { md: "7" } },
                              [
                                _c("Message", {
                                  ref: item.message_uuid,
                                  refInFor: true,
                                  attrs: {
                                    id:
                                      "message_" +
                                      item.message_uuid.replace(":", ""),
                                    message_uuid: item.message_uuid,
                                    functionbox_uuid: _vm.functionbox_uuid,
                                    user_uuid: _vm.user_uuid,
                                    "is-conversation": "",
                                  },
                                  on: { gotoMessage: _vm.gotoMessage },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isMe(item)
                          ? _c(
                              "b-col",
                              { attrs: { md: "7", "offset-md": "5" } },
                              [
                                _c("Message", {
                                  attrs: {
                                    id:
                                      "message_" +
                                      item.message_uuid.replace(":", ""),
                                    message_uuid: item.message_uuid,
                                    functionbox_uuid: _vm.functionbox_uuid,
                                    user_uuid: _vm.user_uuid,
                                    "is-conversation": "",
                                  },
                                  on: { gotoMessage: _vm.gotoMessage },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _c("b-pagination", {
            staticClass: "mt-2",
            attrs: {
              "total-rows": _vm.count,
              "per-page": _vm.limit,
              align: "center",
              pills: "",
            },
            on: { input: _vm.getResponses },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _c("iframe", {
            attrs: {
              name: "print_frame",
              width: "100%",
              height: "500px",
              frameborder: "0",
              src: "about:blank",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }