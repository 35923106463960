<template>
  <section v-if="items.length != 0">

    <div class="conversation-subject">{{ subject }}</div>

    <b-form inline class="mt-2">
      <b-button
        v-if="showCloseBtn"
        @click="closeConversation"
        class="no-border"
      >
        <i class="fal fa-times"></i
        ><span class="hidden-mobile">&nbsp;{{ $t("CLOSE") }}</span>
      </b-button>

      
      <span v-if="showCloseBtn">
        <b-button :href="direktLink" class="no-border" target="_blank">
          <i class="fal fa-external-link"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("OPEN_NEW_TAB") }}</span>
        </b-button>
      </span>

      <b-button @click="refreshList" class="no-border"
        ><i class="fal fa-sync"></i
      ></b-button>

      <b-button @click="printBtn" class="no-border">
        <i class="fal fa-print"></i>
        <span class="hidden-mobile">&nbsp;{{ $t("PRINT") }}</span>
      </b-button>

      <span class="i-100">&nbsp;</span>
      
      <span>{{ count }} {{ $t('CONVERSATION_MESSAGES')}}</span>
    </b-form>

    <hr class="pb-0 mb-0 mt-2" />

    <div id="conversationPrintMe">
      <div class="m-2" style="position: relative;" :key="conversationKey">
        <div v-for="(item, index) in items" :key="index">
          <b-row no-gutters>
            <b-col md="7" v-if="!isMe(item)">
              <Message
                v-bind:id="'message_' + item.message_uuid.replace(':', '')"
                :ref="item.message_uuid"
                :message_uuid="item.message_uuid"
                :functionbox_uuid="functionbox_uuid"
                @gotoMessage="gotoMessage"
                :user_uuid="user_uuid"
                is-conversation
              ></Message>
            </b-col>
            <b-col md="7" offset-md="5" v-if="isMe(item)">
              <Message
                v-bind:id="'message_' + item.message_uuid.replace(':', '')"
                :message_uuid="item.message_uuid"
                :functionbox_uuid="functionbox_uuid"
                @gotoMessage="gotoMessage"
                :user_uuid="user_uuid"
                is-conversation
              ></Message>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <b-pagination
      class="mt-2"
      v-model="currentPage"
      :total-rows="count"
      :per-page="limit"
      align="center"
      @input="getResponses"
      pills
    >
    </b-pagination>

    <iframe name="print_frame" width="100%" height="500px" frameborder="0" src="about:blank"></iframe>

  </section>
</template>
<script>
import Message from "@/components/View/Message.vue";
export default {
  props: {
    inbox: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    message_uuid: {
      default: "",
      type: String,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    showCloseBtn: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  components: {
    Message
  },
  data() {
    return {
      firstRun: true,
      subject: "",
      limit: 5,
      conversationIndex: 0,
      showCreate: true,
      response_message_uuid: "",
      items: [],
      count: 0,
      currentPage: 1,
      responded_message_id: "",
      found_page: 0
    };
  },
  methods: {
    gotoMessage(message_uuid){
      if(this.inbox){
        this.$emit("gotoMessage",message_uuid);
      }else{
        if(this.user_uuid != "")
        {
          this.$router.push({
              name: 'MessageViewWithUserUuid',
              params: {
                user_uuid: this.user_uuid,
                message_uuid: this.message_uuid,
              },
            });
        }else{
          if(this.functionbox_uuid != ""){
            this.$router.push({
              name: 'MessageViewWithFunctionBoxUuid2',
              params: {
                functionbox_uuid: this.functionbox_uuid,
                message_uuid: this.message_uuid,
              },
            });
          }else{
            this.$router.push({
              name: 'MessageView',
              params: {
                message_uuid: this.message_uuid
              },
            });
          }
        }
      }
    },
    closeConversation()
    {
      this.$emit('closeConversation');
    },
    createdResponse(item) {
      this.items.unshift(item);
      this.showCreate = false;
    },
    refreshList() {
      this.getResponses();
    },
    cancelReply() {
      this.showCreate = false;
    },
    respondedMessage() {
      this.getResponses();
    },
    isMe(item) {
      if(this.functionbox_uuid != "")
      {
        return this.functionbox_uuid == item.user_uuid;
      }
      if(this.user_uuid != "")
      {
        return this.user_uuid == item.user_uuid;
      }
      return this.user.information.user_uuid == item.user_uuid;
    },
    getResponses: function() {
      let self = this;
      this.error = false;
      this.opened = false;
      this.$http
        .post(this.user.hostname + "/message/conversation/list", {
          message_uuid: this.message_uuid,
          functionbox_uuid: this.functionbox_uuid,
          user_uuid: this.user_uuid,
          page: this.currentPage,
          limit: this.limit,
        })
        .then((response) => {
          self.conversationIndex++;
          self.subject = response.data.subject;
          self.response_message_uuid = response.data.response_message_uuid;
          self.items = response.data.items;
          if (self.items.length > 1) {
            self.showCreate = false;
          }
          self.count = response.data.count;
          self.firstRun = false;
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    printBtn() {
      let start = '<html><head><meta http-equiv=cache-control content="max-age=0"><meta http-equiv=cache-control content=no-cache><meta http-equiv=expires content=0"><meta http-equiv=expires content="Tue, 01 Jan 1980 1:00:00 GMT"><meta http-equiv=pragma content=no-cache><meta charset=utf-8"><meta http-equiv=X-UA-Compatible content="IE=edge"><meta name=viewport content="width=device-width,initial-scale=1"><title>SEFOS</title><link type=text/css rel=stylesheet href=/css/bootstrap.min.css><link rel=apple-touch-icon sizes=180x180 href=/favicon/apple-touch-icon.png><link rel=icon type=image/png sizes=32x32 href=/favicon/favicon-32x32.png><link rel=icon type=image/png sizes=16x16 href=/favicon/favicon-16x16.png><link rel=manifest href=/favicon/site.webmanifest><link rel=stylesheet href=/css/animate.min.css><link rel=stylesheet href=/css/printer.css></head><body>';
      var content = document.getElementById("conversationPrintMe").innerHTML;
      content = content.replace(/(<(pre|script|style|textarea|div)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/g, "$1$3");
      content = content.replaceAll("\n", "<br>");
      window.frames["print_frame"].document.body.innerHTML = start + content + '</body></html>';
      window.frames["print_frame"].window.focus();
      setTimeout(function () { window.frames["print_frame"].window.print(); }, 500);
    },
  },
  computed: {
    direktLink() {
      let url = this.$router.resolve({
                name: 'Conversation',
                query: {
                  user_uuid: this.user_uuid,
                  functionbox_uuid: this.functionbox_uuid,
                  message_uuid: this.message_uuid,
                },
              });
        return url.href;
    },
    conversationKey() {
      return "conversation_" + this.conversationIndex;
    },
  },
  mounted() {
    this.getResponses();
  },
};
</script>
<style lang="css"></style>
